import { mergeQueryKeys } from '@lukemorales/query-key-factory';

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      queries: () => {
        const categories = useCategoriesQueries();
        const companies = useCompaniesQueries();
        const counties = useCountiesQueries();
        const favourites = useFavouritesQueries();
        const fields = useFieldsQueries();
        const filters = useFiltersQueries();
        const icbf = useIcbfQueries();
        const myPosts = useMyPostsQueries();
        const notifications = useNotificationsQueries();
        const notificationTypes = useNotificationTypesQueries();
        const pages = usePagesQueries();
        const payments = usePaymentsQueries();
        const paymentLinks = usePaymentLinksQueries();
        const posts = usePostsQueries();
        const profile = useProfileQueries();
        const threads = useThreadsQueries();
        const user = useUserQueries();

        return mergeQueryKeys(
          categories,
          companies,
          counties,
          favourites,
          fields,
          filters,
          icbf,
          myPosts,
          notifications,
          notificationTypes,
          pages,
          payments,
          paymentLinks,
          posts,
          profile,
          threads,
          user
        );
      },
    },
  };
});
