import Cookies from 'js-cookie';
import { camelizeKeys } from '@/utils/lodash';

const useSanctum = () => {
  const { authenticated, currentUser } = storeToRefs(useAuthStore());

  const { $api } = useNuxtApp();

  const router = useRouter();
  const { logEvent } = useAnalytics();

  const refreshUser = async () => {
    const { data } = await $api.user.authenticate();

    // Dirty hack required because middleware currently runs before plugins
    currentUser.value = camelizeKeys(data.user);
  };

  const authenticate = async (force: boolean = false) => {
    if (!force && authenticated.value) {
      return;
    }

    const token = await getToken();

    if (token) {
      try {
        await refreshUser();
      } catch {
        logout();
      }
    }
  };

  const login = async ({ email, password }) =>
    new Promise(async (resolve, reject) => {
      try {
        const { data } = await $api.user.login({
          email: toValue(email),
          password: toValue(password),
        });

        await setToken(data.token);

        logEvent('account_action', { action_button: 'logged_in' });

        await authenticate();

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  const logout = () => {
    clearToken();

    currentUser.value = null;

    logEvent('account_action', { action_button: 'logged_out' });

    router.push({ name: 'index' });
  };

  const getToken = async () => await Cookies.get('x-agriland-auth-token');

  const setToken = async (token: string) =>
    await Cookies.set('x-agriland-auth-token', token);

  const clearToken = () => Cookies.remove('x-agriland-auth-token');

  return {
    authenticate,
    refreshUser,
    login,
    logout,
    getToken,
    setToken,
    clearToken,
  };
};

export { useSanctum };
