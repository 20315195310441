import { createQueryKeys } from '@lukemorales/query-key-factory';

const useIcbfQueries = () => {
  const { $api, $filterResponse } = useNuxtApp();

  return createQueryKeys('icbf', {
    all: (params = {} as Ref, returns = 'listings') => ({
      queryKey: [params, returns],
      queryFn: (context) =>
        $api.icbf
          .getAll({ ...(params && { params: toValue(params) }) })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
    byId: (id, params = {} as Ref, returns = 'listing') => ({
      queryKey: [id, params, returns],
      queryFn: (context) =>
        $api.icbf
          .getById({
            id: toValue(id),
            ...(params && { params: toValue(params) }),
          })
          .then((resp) => $filterResponse(resp.data, returns)),
    }),
  });
};

export { useIcbfQueries };
