import HttpFactory from '@/repository/factory';

import { type User } from '@/interfaces/User';

class UserModule extends HttpFactory {
  private ENDPOINT = useEnv('APP_ENDPOINT');
  private VERSION = 1;
  private RESOURCE = 'user';
  private AUTHENTICATED = true;
  private BASEURL = `${this.ENDPOINT}/api/v${this.VERSION}/${this.RESOURCE}`;

  async refreshCookie({ params = {}, options: opts = {} } = {}) {
    const url = `${this.ENDPOINT}/sanctum/csrf-cookie`;
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, options, authenticated });
  }

  async authenticate({ params = {}, options: opts = {} } = {}) {
    const url = this.BASEURL;
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, options, authenticated });
  }

  async login({
    email,
    password,
    params = {},
    options: opts = {},
  }: LoginParams) {
    const url = `${this.ENDPOINT}/api/v1/login`;
    const method = 'POST';
    const data = { email, password };
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    await this.refreshCookie();

    return await this.call({ url, method, data, options, authenticated });
  }

  async logout({ params = {}, options: opts = {} } = {}) {
    const url = `${this.ENDPOINT}/api/v1/logout`;
    const method = 'POST';
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, method, options, authenticated });
  }

  async register({
    name,
    email,
    password,
    passwordConfirmation,
    params = {},
    options: opts = {},
  }: RegisterParams) {
    const url = `${this.ENDPOINT}/api/register`;
    const method = 'POST';
    const data = { name, email, password, passwordConfirmation };
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    await this.refreshCookie();

    return await this.call({ url, method, data, options, authenticated });
  }

  async forgotPassword({ email, params = {}, options: opts = {} } = {}) {
    const url = `${this.ENDPOINT}/api/v1/forgot-password`;
    const method = 'POST';
    const data = { email };
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    await this.refreshCookie();

    return await this.call({ url, method, data, options, authenticated });
  }

  async resetPassword({
    email = '',
    password = '',
    passwordConfirmation = '',
    token = '',
    params = {},
    options: opts = {},
  } = {}) {
    const url = `${this.ENDPOINT}/api/reset-password`;
    const method = 'POST';
    const data = { email, password, passwordConfirmation, token };
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    await this.refreshCookie();

    return await this.call({ url, method, data, options, authenticated });
  }

  async getPayments({ params = {}, options: opts = {} } = {}) {
    const url = `${this.BASEURL}/payments`;
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, options, authenticated });
  }

  async delete({ params = {}, options: opts = {} } = {}) {
    console.log('triggered delete');
    const url = `${this.BASEURL}`;
    const method = 'POST';
    const data = { _method: 'delete' };
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, method, data, options, authenticated });
  }

  async blockUser(seller, { params = {}, options: opts = {} } = {}) {
    console.log('triggered user block');

    console.log(params);
    const url = `${this.BASEURL}/block`;
    const method = 'POST';
    const data = { seller };
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, method, data, options, authenticated });
  }

  async verifyEmail({ token = '', params = {}, options: opts = {} } = {}) {
    const url = `${this.ENDPOINT}/api/v1/verify-email/${token}`;
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, options, authenticated });
  }

  async sendCode({
    prefix = '',
    number = '',
    params = {},
    options: opts = {},
  } = {}) {
    const url = `${this.BASEURL}/verify-phone`;
    const method = 'POST';
    const data = { prefix, number };
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, method, data, options, authenticated });
  }

  async verifyPhone({
    code = '',
    phone = '',
    params = {},
    options: opts = {},
  } = {}) {
    const url = `${this.BASEURL}/verify-code`;
    const method = 'POST';
    const data = { code, phone };
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, method, data, options, authenticated });
  }

  async icbfLogin({ code = '', params = {}, options: opts = {} } = {}) {
    const url = `${this.BASEURL}/icbf-login`;
    const method = 'POST';
    const data = { code };
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, method, data, options, authenticated });
  }

  async icbfLogout({ params = {}, options: opts = {} } = {}) {
    const url = `${this.BASEURL}/icbf-logout`;
    const method = 'DELETE';
    const data = {};
    const options = { ...opts, params };
    const authenticated = this.AUTHENTICATED;

    return await this.call({ url, method, data, options, authenticated });
  }
}

export { UserModule };
